function setTabindexDisabled(el) {
    el.setAttribute("tabindex", "-1");
}

if (document.getElementById("hero__swiper")) {
    const swiperHero = new Swiper("#hero__swiper .swiper", {
        effect: "fade",
        speed: 5000,
        lazy: true,
        loop: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd",
            paginationBulletMessage: 'Przejdź na slajd {{index}}',
        },
        autoplay: {
            delay: 5000,
            speed: 3000,
            disableOnInteraction: false,
        },

        pagination: {
            el: "#hero__swiper .swiper-pagination",
            // clickable: "true",
        },
        navigation: {
            nextEl: "#hero__swiper .swiper-button-next",
            prevEl: "#hero__swiper .swiper-button-prev",
        },
        slideActiveClass: 'swiper-slide-active',
        on: {
            init: function () {
                document.querySelector("#hero__swiper .swiper-slide-active").classList.add("swiper-slide-active-transition");

            },
            resize: function () {
                document.querySelector("#hero__swiper .swiper-slide-active").classList.add("swiper-slide-active-transition");
            },
            slideChange() {
                let elToDisabled = document.querySelectorAll("#hero__swiper .swiper-button-next, #hero__swiper .swiper-button-prev, #hero__swiper .swiper-pagination-bullet");
                for (let i = 0; i < elToDisabled.length; i++) {
                    setTabindexDisabled(elToDisabled[i]);
                }
            }

        },
    });
    swiperHero.on('slideChangeTransitionStart', function (swiper, transition) {

        let classN = document.querySelector("#hero__swiper .swiper-slide-active").getAttribute("data-class");
        document.getElementById("hero__swiper").setAttribute("data-class", classN);
        if (!document.querySelector("#hero__swiper .swiper-slide-active .hero__img").classList.contains("isloading")) {
            document.querySelector("#hero__swiper .swiper-slide-active .hero__img").classList.add("isloading");
            const img = document.querySelector("#hero__swiper .swiper-slide-active .hero__img").getAttribute("data-src");
            document.querySelector("#hero__swiper .swiper-slide-active .hero__img").setAttribute("src", img);
        }
        document.querySelector("#hero__swiper .swiper-slide-active").classList.add("swiper-slide-active-transition");
    });

    swiperHero.on('beforeTransitionStart', function (swiper) {
        if (document.querySelector("#hero__swiper .swiper-slide-active-transition")) {
            document.querySelector("#hero__swiper .swiper-slide-active-transition").classList.remove("swiper-slide-active-transition");
        }
    });
}
if (document.getElementById("swiperCompetition")) {
    const swiperHero = new Swiper("#swiperCompetition", {
        effect: "fade",
        speed: 5000,
        lazy: true,
        loop: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd",
            paginationBulletMessage: 'Przejdź na slajd {{index}}',
        },
        autoplay: {
            delay: 5000,
            speed: 3000,
            disableOnInteraction: false,
        },

        pagination: {
            el: "#swiperCompetition .swiper-pagination",
            // clickable: "true",
        },
        navigation: {
            nextEl: "#swiperCompetition .swiper-button-next",
            prevEl: "#swiperCompetition .swiper-button-prev",
        },
        slideActiveClass: 'swiper-slide-active',
        on: {
            init: function () {
                document.querySelector("#swiperCompetition .swiper-slide-active").classList.add("swiper-slide-active-transition");

            },
            resize: function () {
                document.querySelector("#swiperCompetition .swiper-slide-active").classList.add("swiper-slide-active-transition");
            },
            slideChange() {
                let elToDisabled = document.querySelectorAll("#hero__swiper .swiper-button-next, #hero__swiper .swiper-button-prev, #hero__swiper .swiper-pagination-bullet");
                for (let i = 0; i < elToDisabled.length; i++) {
                    setTabindexDisabled(elToDisabled[i]);
                }
            }

        },
    });
    swiperHero.on('slideChangeTransitionStart', function (swiper, transition) {

        document.querySelector("#swiperCompetition .swiper-slide-active").classList.add("swiper-slide-active-transition");
    });

    swiperHero.on('beforeTransitionStart', function (swiper) {
        if (document.querySelector("#swiperCompetition .swiper-slide-active-transition")) {
            document.querySelector("#swiperCompetition .swiper-slide-active-transition").classList.remove("swiper-slide-active-transition");
        }
    });
}

if (document.getElementById("heroProductSwiper")) {
    const swiperOpinion = new Swiper("#heroProductSwiper .swiper", {
        loop: true,
        keyboard: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd",
            paginationBulletMessage: 'Przejdź na slajd {{index}}',

        },
        autoplay: {
            delay: 6000,
            speed: 3000,
            disableOnInteraction: false,
        },
        pagination: {
            el: "#heroProductSwiper .swiper-pagination",
            clickable: "true",
        },
        navigation: {
            nextEl: "#heroProductSwiper .swiper-button-next",
            prevEl: "#heroProductSwiper .swiper-button-prev",
        },
        slideActiveClass: 'swiper-slide-active',
    });
}


if (document.querySelectorAll(".regions__swiper").length > 0) {
    let swiperSliders = document.querySelectorAll(".regions__swiper");
    for (let i = 0; i < swiperSliders.length; i++) {
        let swiperItem = swiperSliders[i];
        let swiperSlider = swiperItem.querySelector(".swiper");
        let prev = swiperItem.querySelector(".swiper-button-prev");
        let next = swiperItem.querySelector(".swiper-button-next");
        let scroll = swiperItem.querySelector(".swiper-scrollbar");
        var swiper = new Swiper(swiperSlider, {
            slidesPerView: 1.2,
            // loop: true,
            lazy: true,
            a11y: {
                nextSlideMessage: "Następny slajd",
                prevSlideMessage: "Poprzedni slajd",
                firstSlideMessage: "Pierwszy slajd",
                lastSlideMessage: "Ostatni slajd",

            },
            spaceBetween: 5,
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            scrollbar: {
                el: scroll,
                hide: false,
                draggable: true,
            },

            breakpoints: {
                576: {
                    slidesPerView: 1.8,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2.6,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 3.4,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 3.8,
                    spaceBetween: 40,
                },
            },
            keyboard: true,
            a11y: {
                nextSlideMessage: "Następny slajd",
                prevSlideMessage: "Poprzedni slajd",
                firstSlideMessage: "Pierwszy slajd",
                lastSlideMessage: "Ostatni slajd"
            },
        });
    }
}

if (document.querySelectorAll(".productSwiper2").length > 0) {
    let swiperSliders = document.querySelectorAll(".productSwiper2");
    for (let i = 0; i < swiperSliders.length; i++) {
        let swiperItem = swiperSliders[i];
        let swiperSlider = swiperItem.querySelector(".swiper");
        let prev = swiperItem.querySelector(".swiper-button-prev");
        let next = swiperItem.querySelector(".swiper-button-next");
        let pagination = swiperItem.querySelector(".swiper-pagination");
        var swiper = new Swiper(swiperSlider, {
            // loop: true,
            lazy: true,
            spaceBetween: 5,
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            pagination: {
                el: pagination,
                clickable: "true",
            },

            keyboard: true,
            a11y: {
                nextSlideMessage: "Następny slajd",
                prevSlideMessage: "Poprzedni slajd",
                firstSlideMessage: "Pierwszy slajd",
                lastSlideMessage: "Ostatni slajd"
            },
        });
    }
}


if (document.querySelectorAll(".tabs__swiper").length > 0) {


    let swiperSliders = document.querySelectorAll(".tabs__swiper");
    for (let i = 0; i < swiperSliders.length; i++) {
        let swiperItem = swiperSliders[i];
        let swiperSlider = swiperItem.querySelector(".swiper");
        let prev = swiperItem.querySelector(".swiper-button-prev");
        let next = swiperItem.querySelector(".swiper-button-next");
        let scroll = swiperItem.querySelector(".swiper-scrollbar");
        var swiper = new Swiper(swiperSlider, {
            slidesPerView: 1.8,
            // loop: true,
            lazy: true,
            a11y: {
                nextSlideMessage: "Następny slajd",
                prevSlideMessage: "Poprzedni slajd",
                firstSlideMessage: "Pierwszy slajd",
                lastSlideMessage: "Ostatni slajd",

            },
            spaceBetween: 20,
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            scrollbar: {
                el: scroll,
                hide: false,
                draggable: true,
            },

            breakpoints: {
                576: {
                    slidesPerView: 2.8,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3.2,
                    spaceBetween: 20,
                },
                992: {
                    slidesPerView: 3.8,
                    spaceBetween: 20,
                },
                1200: {
                    slidesPerView: 4.8,
                    spaceBetween: 20,
                },
                1600: {
                    slidesPerView: 5.8,
                    spaceBetween: 20,
                },
            },
            keyboard: true,
            a11y: {
                nextSlideMessage: "Następny slajd",
                prevSlideMessage: "Poprzedni slajd",
                firstSlideMessage: "Pierwszy slajd",
                lastSlideMessage: "Ostatni slajd"
            },
        });
    }
}

if (document.querySelectorAll(".dzn__swiper").length > 0) {
    var swiper = new Swiper(".dzn__swiper", {
        slidesPerView: 1,
        loop: true,
        lazy: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd",

        },
        spaceBetween: 5,
        navigation: {
            nextEl: ".dzn__swiper .swiper-button-next",
            prevEl: ".dzn__swiper .swiper-button-prev",
        },

        pagination: {
            el: ".dzn__swiper .swiper-pagination",
            // clickable: "true",
        },
        breakpoints: {
            576: {
                slidesPerView: 2,
                spaceBetween: 10,
            },
            768: {
                slidesPerView: 3,
                spaceBetween: 0,
            },
            1200: {
                slidesPerView: 4,
            },
        },
        keyboard: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd"
        },
    });
}


if (document.querySelectorAll(".product__swiper").length > 0) {

    let swiperSliders = document.querySelectorAll(".product__swiper");

    for (let i = 0; i < swiperSliders.length; i++) {
        let swiperItem = swiperSliders[i];
        let prev = swiperItem.querySelector(".swiper-button-prev");
        let next = swiperItem.querySelector(".swiper-button-next");
        const swiperProduct = new Swiper(swiperItem, {
            lazy: true,
            // loop: true,
            keyboard: true,
            slidesPerView: 1.2,
            spaceBetween: 20,
            a11y: {
                nextSlideMessage: "Następny slajd",
                prevSlideMessage: "Poprzedni slajd",
                firstSlideMessage: "Pierwszy slajd",
                lastSlideMessage: "Ostatni slajd"
            },
            navigation: {
                nextEl: next,
                prevEl: prev,
            },
            pagination: {
                el: swiperItem.querySelector(".swiper-pagination"),
                // clickable: "true",
            },
            slideActiveClass: 'swiper-slide-active',
            breakpoints: {
                576: {
                    slidesPerView: 2.2,
                },
                1200: {
                    spaceBetween: 20,
                    slidesPerView: 3,
                },
                1400: {
                    spaceBetween: 30,
                    slidesPerView: 4,
                }
            }


        });
        // swiperProduct.on('slideChangeTransitionStart', function (swiper, transition) {
        //
        //     if (!swiperItem.querySelector(".swiper-slide-active .swiper-lazy-loaded").classList.contains("isloading")) {
        //         swiperItem.querySelector(".swiper-slide-active .swiper-lazy-loaded").classList.add("isloading");
        //     }
        //
        // });
        prev.addEventListener("click", function (e) {
            e.stopPropagation();
        });
        next.addEventListener("click", function (e) {
            e.stopPropagation();
        });

    }

}

if (document.getElementById("opinion__swiper")) {
    const swiperOpinion = new Swiper("#opinion__swiper .swiper", {
        // loop: true,
        keyboard: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd",
            paginationBulletMessage: 'Przejdź na slajd {{index}}',

        },
        autoplay: {
            delay: 6000,
            speed: 3000,
            disableOnInteraction: false,
        },
        pagination: {
            el: ".opinion__pagination",
            // clickable: "true",
        },
        navigation: {
            nextEl: "#opinion__swiper .swiper-button-next",
            prevEl: "#opinion__swiper .swiper-button-prev",
        },
        slideActiveClass: 'swiper-slide-active',
    });
}

if (document.getElementById("individualExpeditions")) {
    const swiperOpinion = new Swiper("#individualExpeditions", {
        // loop: true,
        keyboard: true,
        slidesPerView: 1.1,
        spaceBetween: 20,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd",
            paginationBulletMessage: 'Przejdź na slajd {{index}}',

        },
        // autoplay: {
        //     delay: 6000,
        //     speed: 3000,
        //     disableOnInteraction: false,
        // },
        pagination: {
            el: ".opinion__pagination",
            // clickable: "true",
        },
        navigation: {
            nextEl: "#individualExpeditions .swiper-button-next",
            prevEl: "#individualExpeditions .swiper-button-prev",
        },
        slideActiveClass: 'swiper-slide-active',
        breakpoints: {
            640: {
                slidesPerView: 2.2,
            },
            1200: {
                spaceBetween: 30,
                slidesPerView: 3.5,
            }
        }
    });
}


if (document.getElementById("swiperLastPlaces")) {
    const swiperLastPlaces = new Swiper("#swiperLastPlaces", {
        loop: true,
        keyboard: true,
        lazy: true,
        effect: "fade",
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd",
            paginationBulletMessage: 'Przejdź na slajd {{index}}',

        },
        // autoplay: {
        //     delay: 6000,
        //     speed: 3000,
        //     disableOnInteraction: false,
        // },
        pagination: {
            el: "#swiperLastPlaces .swiper-pagination",
            // clickable: "true",
        },
        navigation: {
            nextEl: "#swiperLastPlaces .swiper-button-next",
            prevEl: "#swiperLastPlaces .swiper-button-prev",
        },
        slideActiveClass: 'swiper-slide-active',


    });

}


if (document.querySelectorAll(".pr__swiper").length > 0) {
    const swiper = new Swiper(".pr__swiper", {
        slidesPerView: 1,
        // loop: true,
        lazy: true,
        keyboard: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd"

        },
        spaceBetween: 5,
        navigation: {
            nextEl: ".pr__swiper .swiper-button-next",
            prevEl: ".pr__swiper .swiper-button-prev",
        },
        breakpoints: {
            992: {
                slidesPerView: 2,
                spaceBetween: 10,
            },

            1200: {
                slidesPerView: 3,
                spaceBetween: 20,
            },
        },
    });
}

if (document.querySelectorAll(".quide__swiper").length > 0) {
    const swiper = new Swiper(".quide__swiper", {
        slidesPerView: 2,
        autoplay: {
            delay: 2500,
            disableOnInteraction: false,
        },
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd"
        },
        spaceBetween: 5,
        navigation: {
            nextEl: ".quide__swiper .swiper-button-next",
            prevEl: ".quide__swiper .swiper-button-prev",
        },

        breakpoints: {
            576: {
                slidesPerView: 4,
                spaceBetween: 10,
            },

            1200: {
                slidesPerView: 5,
                spaceBetween: 20,
            },
        },
    });
}


if (document.querySelectorAll(".gallerySwiper").length > 0) {
    var swiper = new Swiper(".gallerySwiper", {
        slidesPerView: 3.3,
        loop: true,
        lazy: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd",

        },
        spaceBetween: 5,
        navigation: {
            nextEl: ".gallerySwiper .swiper-button-next",
            prevEl: ".gallerySwiper .swiper-button-prev",
        },
        breakpoints: {
            576: {
                slidesPerView: 4.3,
                spaceBetween: 10,
            },
            992: {
                slidesPerView: 5.3,
                spaceBetween: 10,
            },
            1200: {
                slidesPerView: 7.3,
                spaceBetween: 15,
            },
        },
        keyboard: true,
        a11y: {
            nextSlideMessage: "Następny slajd",
            prevSlideMessage: "Poprzedni slajd",
            firstSlideMessage: "Pierwszy slajd",
            lastSlideMessage: "Ostatni slajd"
        },

    });
}
